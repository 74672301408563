import { Injectable, inject } from "@angular/core";
import { ClientService } from "../client/client.service";
import { Observable, switchMap } from "rxjs";
import { accountinternal, entities } from "../client/client";

@Injectable({
    providedIn: 'root'
})
export class PetsDataService {

    clientService = inject(ClientService);

    public listPets(userId: string): Observable<entities.ListPetsResponse> {
        return this.clientService.client.pipe(
            switchMap(client => client.accountexternal.ListPets(userId))
        );
    }

    public getPet(userId: string, petId: string): Observable<entities.PetResponse> {
        return this.clientService.client.pipe(
            switchMap(client => client.accountexternal.GetPet(userId, petId))
        );
    }

    public searchPets(searchPetsRequest: accountinternal.SearchPetsRequestPaginated): Observable<accountinternal.SearchPetsResponsePaginated> {
        return this.clientService.client.pipe(
            switchMap(client => client.accountinternal.SearchPets(searchPetsRequest))
        );
    }

    public getPetInternal(userId: string, petId: string): Observable<accountinternal.PetResponse> {
        return this.clientService.client.pipe(
            switchMap(client => client.accountinternal.GetPet(userId, petId))
        );
    }

    public listPetsInternal(userId: string): Observable<entities.ListPetsResponse> {
        return this.clientService.client.pipe(
            switchMap(client => client.accountinternal.ListPets(userId))
        );
    }
}